import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Under contruction!<br/>
          Need something fun built in the meantime?<br/>
          Say <a href={"mailto:hello@nitrocade.com"} className={"App-link"}>hello</a>.
        </p>
      </header>
    </div>
  );
}

export default App;
